import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDMzb4ClUZjiyYk_CbfMiHZITrLwGJURCY",
  authDomain: "futapp-16af0.firebaseapp.com",
  projectId: "futapp-16af0",
  storageBucket: "futapp-16af0.appspot.com",
  messagingSenderId: "1094027672780",
  appId: "1:1094027672780:web:2d744aebf929e98de49669",
  measurementId: "G-HBS5XNS482"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)



export { app, auth };
